<template>
    <div class="form-container">
        <div class="pb-8px">
            <v-row no-gutters justify="space-between" align="center">
                <v-col cols="auto">
                    <tit-tertiary>고객정보</tit-tertiary>
                </v-col>
                <v-col cols="auto">
                    <txt-primary class="txt--sm"><span class="pink--text">*</span> 필수입력 항목입니다.</txt-primary>
                </v-col>
            </v-row>
        </div>
        <v-form v-model="isValid">
            <vertical-form-table v-model="form" v-bind="{ items: computedItems }">
                <template slot="배우자 정보">
                    <v-row no-gutters class="inner-form">
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px">이름 <span class="red--text">*</span></div>
                                </v-col>
                                <v-col>
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                                        <v-text-field v-model="form.spouse.name" outlined hide-details="auto" placeholder="이름을 입력하세요." :rules="rules.name" />
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider />
                            <v-row align="center">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px">연락처 <span class="red--text">*</span></div>
                                </v-col>
                                <v-col>
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                                        <v-text-field v-model="form.spouse.phone" outlined hide-details="auto" placeholder="'-'를 제외하고 입력하세요." :rules="rules.phone" />
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider />
                            <v-row align="center">
                                <v-col cols="12" md="auto">
                                    <div class="w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px">생년월일<span class="red--text">*</span></div>
                                </v-col>
                                <v-col>
                                    <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                                        <v-text-field v-model="form.spouse.birth" outlined hide-details="auto" placeholder="생년월일(YYYYMMDD)을 입력하세요." :rules="rules.birth" />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </template>
                <template slot="신청자격">
                    <v-radio-group v-model="form.qualification_type" hide-details="auto" row @change="emit">
                        <v-radio v-for="(item, index) in Object.values(QUALIFICATION_TYPE)" :key="index" outlined color="tertiary" :value="item.value" :label="item.text" />
                    </v-radio-group>
                </template>
                <template slot="첨부파일1">
                    <v-file-input outlined v-model="form.files.file1" row placeholder="청약시 공통서류제출" messages="* 5MB 이하의 PDF 파일만 등록이 가능합니다." accept=".pdf" />
                </template>
                <template slot="첨부파일2">
                    <v-file-input outlined v-model="form.files.file2" row placeholder="파일을 첨부하세요." messages="* 5MB 이하의 PDF 파일만 등록이 가능합니다." accept=".pdf" />
                </template>
                <template slot="첨부파일3">
                    <v-file-input outlined v-model="form.files.file3" row placeholder="파일을 첨부하세요." messages="* 5MB 이하의 PDF 파일만 등록이 가능합니다." accept=".pdf" />
                </template>
                <template slot="첨부파일4">
                    <v-file-input outlined v-model="form.files.file4" row placeholder="파일을 첨부하세요." messages="* 5MB 이하의 PDF 파일만 등록이 가능합니다." accept=".pdf" />
                </template>
            </vertical-form-table>
            <div class="mt-20px mt-lg-40px">
                <terms-of-agreements-short :params="{ codes: ['collect-use'] }" v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" @input="emitTerms" />
            </div>

            <sheet-primary class="text-center my-40px my-lg-60px py-30px py-lg-40px">
                <txt-quarternary>
                    모집공고 전문을 반드시 숙지 후 청약신청을 진행해주시기 바라며, <br class="d-none d-lg-block" />
                    미숙지로 인한 착오 신청 등에 대해서는 <span class="secondary--text">신청자 본인에게 책임이 있으니 유의하여 주시기 비랍니다.</span>
                </txt-quarternary>
            </sheet-primary>

            <div class="btn-wrap btn-wrap--lg">
                <v-row justify="center" class="row--sm">
                    <v-col cols="6" sm="auto">
                        <v-btn tile x-large outlined color="grey lighten-3" class="w-100 min-w-sm-160px" @click="$router.go(-1)"><span class="grey--text">취소</span></v-btn>
                    </v-col>
                    <v-col cols="6" sm="auto">
                        <v-btn tile x-large color="grey darken-3" class="w-100 min-w-sm-160px" v-bind="{ disabled, loading }" @click="validates"
                            ><span class="white--text">{{ text }}</span></v-btn
                        >
                    </v-col>
                </v-row>
            </div>
        </v-form>

        <!-- <v-dialog v-model="dialog" width="588">
            <v-card tile flat class="py-40px py-md-60px py-lg-80px">
                <txt-primary class="text-center">
                    등록 후 수정이 불가능합니다.<br />
                    내용을 다시 한번 확인 후 등록 바랍니다.<br />
                    등록하시겠습니까?
                </txt-primary>
                <div class="btn-wrap">
                    <v-row class="row--xs" justify="center">
                        <v-col cols="auto">
                            <v-btn x-large tile outlined class="w-160px" color="grey lighten-3" @click="() => (dialog = false)">
                                <span class="grey--text">취소</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn x-large tile class="w-160px white--text" color="black" @click="validates">등록</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import api from "@/api";
import cryptoAes from "@/plugins/crypto-aes";
import { SUPPLY_CATEGORY, QUALIFICATION_TYPE, SUPPLY_TYPE, rules, initApplication, RANKING_LIST, BOOLEAN_LIST, initSpouse } from "@/assets/variables";

import PageSection from "@/sets/styles/pages/page-section.vue";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/terms/terms-of-agreements-short.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";
import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";
// import ImagesInput from "@/components/console/dumb/images-input.vue";
// import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";

const items = [
    {
        key: "name",
        term: "이름",
        type: "text",
        required: true,
        readonly: true,
    },
    {
        key: "phone",
        term: "연락처",
        type: "text",
        required: true,
        readonly: true,
    },
    {
        key: "birth",
        term: "생년월일",
        type: "text",
        required: true,
        readonly: true,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
        rules: rules.email,
        placeholder: "이메일을 입력하세요.",
    },
    {
        key: "qualification_type",
        term: "신청자격",
        type: "radio",
        radioItems: Object.values(QUALIFICATION_TYPE),
        required: true,
    },
    {
        term: "배우자 정보",
        required: true,
    },
    {
        key: "supply_category",
        term: "공급유형",
        type: "radio",
        radioItems: Object.values(SUPPLY_CATEGORY),
        required: true,
    },
    {
        key: "supply_type",
        term: "공급타입",
        type: "radio",
        radioItems: Object.values(SUPPLY_TYPE),
        required: true,
    },
    {
        key: "earning_rank",
        term: "소득순위",
        type: "radio",
        radioItems: Object.values(RANKING_LIST),
        required: true,
    },
    {
        key: "region_rank",
        term: "지역순위",
        type: "radio",
        radioItems: Object.values(RANKING_LIST),
        required: true,
    },
    {
        key: "isHomeless",
        term: "무주택 충족 여부",
        type: "radio",
        radioItems: Object.values(BOOLEAN_LIST),
        required: true,
    },
    {
        key: "isAsset",
        term: "자산기준 충족 여부",
        type: "radio",
        radioItems: Object.values(BOOLEAN_LIST),
        required: true,
    },
    {
        key: "isCar",
        term: "자동차가액 충족 여부",
        type: "radio",
        radioItems: Object.values(BOOLEAN_LIST),
        required: true,
    },
    { term: "첨부파일1", required: true },
    { term: "첨부파일2" },
    { term: "첨부파일3" },
    { term: "첨부파일4" },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        PageSection,
        VerticalFormTable,
        VEmailField,
        TermsOfAgreementsShort,
        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
        SheetPrimary,
    },
    props: {
        value: { type: Object, default: null },
        loading: { type: Boolean, default: false },
    },
    data() {
        return {
            dialog: false,
            rules,
            items,
            SUPPLY_CATEGORY,
            QUALIFICATION_TYPE,
            SUPPLY_TYPE,

            form: initApplication(),
            isValid: false,
            isTermsValid: undefined,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!this._application && !this._certification) {
                alert("잘못된 접근입니다.");
                this.$router.go(-1);
            }
            if (this._application) this.search();
            if (this._certification) this.getCertification();
        },

        async search() {
            try {
                const { application } = await api.v1.application.get({ _id: this._application });
                this.form = initApplication(application);
            } catch (error) {
                console.error(error);
            }
        },

        async getCertification() {
            try {
                const {
                    certification: { name, phone, birth, ci },
                } = await api.v1.cert.get({ _id: this._certification });
                this.form = initApplication({ name, phone, birth, ci: cryptoAes.decrypt(ci) });
            } catch (error) {
                console.error(error);
                alert(error?.response ? error.response.data.message : error.message);
            }
        },

        sync() {
            this.form = initApplication(this.value);
        },

        emitTerms(value) {
            this.form = { ...this.form, _terms: [...value] };
        },

        emit(value) {
            this.form = { ...this.form, qualification_type: value, spouse: initSpouse() };
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }

                if (!this.form?.name || !this.form?.phone || !this.form?.email) throw new Error("본인인증이 필요합니다.");
                if (!this.form?.email) throw new Error("이메일을 입력해주세요");
                if (!this.form?.qualification_type) throw new Error("신청자격을 선택해주세요.");
                if (this.form?.qualification_type === QUALIFICATION_TYPE.MARRIED.value) {
                    if (!this.form?.spouse?.name) throw new Error("배우자 이름을 입력해주세요.");
                    if (!this.form?.spouse?.phone) throw new Error("배우자 연락처를 입력해주세요.");
                    if (!this.form?.spouse?.birth) throw new Error("배우자 생년월일을 입력해주세요.");
                }

                if (!this.form?.supply_category) throw new Error("공급유형을 선택해주세요.");
                if (!this.form?.supply_type) throw new Error("공급타입을 선택해주세요.");
                if (!this.form?.earning_rank) throw new Error("소득순위를 선택해주세요.");
                if (!this.form?.region_rank) throw new Error("지역순위를 선택해주세요.");
                if (this.form?.isHomeless == null) throw new Error("무주택 충족여부를 선택해주세요.");
                if (this.form?.isAsset == null) throw new Error("자산기준 충족여부를 선택해주세요.");
                if (this.form?.isCar == null) throw new Error("자동차가액 충족여부를 선택해주세요.");
                if (!this.form?.files?.file1) throw new Error("청약서 공통서류를 첨부해주세요.");

                for (let file of Object.values(this.form.files)) {
                    if (file instanceof File) {
                        if (file?.size > 5000000) throw new Error(`5MB 이하의 PDF 파일만 등록 가능합니다.\n파일명: ${file?.name}`);
                        if (file.type !== "application/pdf") throw new Error(`PDF 파일만 등록 가능합니다.\n파일명: ${file.name}`);
                    }
                }

                this.$emit("save", this.form);
            } catch (error) {
                alert(error?.message);
                return false;
            }
        },
    },
    computed: {
        _certification() {
            return this.$route.query._certification;
        },
        _application() {
            return this.$route.params._application;
        },
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
        text() {
            return this._certification ? "접수" : "수정";
        },
        computedItems() {
            if (this.form.qualification_type !== QUALIFICATION_TYPE.MARRIED.value) {
                return items.filter((item) => item.term !== "배우자 정보");
            }
            return items;
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style lang="scss" scoped>
.inner-form {
    background-color: #fff;
    border-radius: 8px;
}
</style>
