var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container"
  }, [_c('div', {
    staticClass: "pb-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tit-tertiary', [_vm._v("고객정보")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    staticClass: "txt--sm"
  }, [_c('span', {
    staticClass: "pink--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")])], 1)], 1)], 1), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.computedItems
  }, false), [_c('template', {
    slot: "배우자 정보"
  }, [_c('v-row', {
    staticClass: "inner-form",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px"
  }, [_vm._v("이름 "), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])])]), _c('v-col', [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "이름을 입력하세요.",
      "rules": _vm.rules.name
    },
    model: {
      value: _vm.form.spouse.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "name", $$v);
      },
      expression: "form.spouse.name"
    }
  })], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px"
  }, [_vm._v("연락처 "), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])])]), _c('v-col', [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "'-'를 제외하고 입력하세요.",
      "rules": _vm.rules.phone
    },
    model: {
      value: _vm.form.spouse.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "phone", $$v);
      },
      expression: "form.spouse.phone"
    }
  })], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px"
  }, [_vm._v("생년월일"), _c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")])])]), _c('v-col', [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "auto",
      "placeholder": "생년월일(YYYYMMDD)을 입력하세요.",
      "rules": _vm.rules.birth
    },
    model: {
      value: _vm.form.spouse.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "birth", $$v);
      },
      expression: "form.spouse.birth"
    }
  })], 1)])], 1)], 1)], 1)], 1), _c('template', {
    slot: "신청자격"
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "row": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.qualification_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "qualification_type", $$v);
      },
      expression: "form.qualification_type"
    }
  }, _vm._l(Object.values(_vm.QUALIFICATION_TYPE), function (item, index) {
    return _c('v-radio', {
      key: index,
      attrs: {
        "outlined": "",
        "color": "tertiary",
        "value": item.value,
        "label": item.text
      }
    });
  }), 1)], 1), _c('template', {
    slot: "첨부파일1"
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "row": "",
      "placeholder": "청약시 공통서류제출",
      "messages": "* 5MB 이하의 PDF 파일만 등록이 가능합니다.",
      "accept": ".pdf"
    },
    model: {
      value: _vm.form.files.file1,
      callback: function ($$v) {
        _vm.$set(_vm.form.files, "file1", $$v);
      },
      expression: "form.files.file1"
    }
  })], 1), _c('template', {
    slot: "첨부파일2"
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "row": "",
      "placeholder": "파일을 첨부하세요.",
      "messages": "* 5MB 이하의 PDF 파일만 등록이 가능합니다.",
      "accept": ".pdf"
    },
    model: {
      value: _vm.form.files.file2,
      callback: function ($$v) {
        _vm.$set(_vm.form.files, "file2", $$v);
      },
      expression: "form.files.file2"
    }
  })], 1), _c('template', {
    slot: "첨부파일3"
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "row": "",
      "placeholder": "파일을 첨부하세요.",
      "messages": "* 5MB 이하의 PDF 파일만 등록이 가능합니다.",
      "accept": ".pdf"
    },
    model: {
      value: _vm.form.files.file3,
      callback: function ($$v) {
        _vm.$set(_vm.form.files, "file3", $$v);
      },
      expression: "form.files.file3"
    }
  })], 1), _c('template', {
    slot: "첨부파일4"
  }, [_c('v-file-input', {
    attrs: {
      "outlined": "",
      "row": "",
      "placeholder": "파일을 첨부하세요.",
      "messages": "* 5MB 이하의 PDF 파일만 등록이 가능합니다.",
      "accept": ".pdf"
    },
    model: {
      value: _vm.form.files.file4,
      callback: function ($$v) {
        _vm.$set(_vm.form.files, "file4", $$v);
      },
      expression: "form.files.file4"
    }
  })], 1)], 2), _c('div', {
    staticClass: "mt-20px mt-lg-40px"
  }, [_c('terms-of-agreements-short', {
    attrs: {
      "params": {
        codes: ['collect-use']
      }
    },
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      },
      "input": _vm.emitTerms
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('sheet-primary', {
    staticClass: "text-center my-40px my-lg-60px py-30px py-lg-40px"
  }, [_c('txt-quarternary', [_vm._v(" 모집공고 전문을 반드시 숙지 후 청약신청을 진행해주시기 바라며, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 미숙지로 인한 착오 신청 등에 대해서는 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("신청자 본인에게 책임이 있으니 유의하여 주시기 비랍니다.")])])], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "tile": "",
      "x-large": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "tile": "",
      "x-large": "",
      "color": "grey darken-3"
    },
    on: {
      "click": _vm.validates
    }
  }, 'v-btn', {
    disabled: _vm.disabled,
    loading: _vm.loading
  }, false), [_c('span', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm.text))])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }